import { defineStore } from "pinia";
import {
  ContractStoreState,
  specialization,
  rateType
} from "../types/storeTypes";

export const useContractStore = defineStore({
  id: "contract",
  state: (): ContractStoreState => ({
    contractId: "",
    organisationId: "",
    organisationName: "",
    clientId: "",
    contractName: "",
    effectiveDate: "",
    endDate: "",
    discount: "",
    includeTraining: "no",
    trainingDiscount: "",
    trainingDuration: "",
    trainingCount: "",
    skill: "",
    trainingsSkillsNeeded: [],
    professionalServiceAgreement: "no",
    ratePerProject: "",
    data: [],
    organisationContactPersonName: "",
    organisationContactPersonPosition: "C.E.O",
    specialisations: [],
    selectedSpecialisations: [],
    listOfClients: [],
    file_effective_date: "",
    agreementFiles: [],
    serviceAgreementName: "",
    file_url: "",
    rateType: "",
    selectApprover: false,
    approveContract: false,
    signContract: false,
    declineContract: false,
    contractComment: false,
    signatureField: false,
    commentMessage: "",
    commenter_id: 0,
    approver: {
      full_name: "",
      employee_id: "",
      work_email: ""
    },
    approverSignature: "",
    contractDownload: false,
    declineMessage: "",
    validateButton: true,
    allEmployees: [],
    listOfOrganisations: [],
    listOfSeniorityLevels: [],
    customizeContractTemplate: false,
    templateType: "project",
    checkedOptions: [],
    suppliers: [],
    customizableOptions: [
      "Statement of work",
      "Introduction",
      "Project fees",
      "Working terms",
      "Feature implementation",
      "Note",
      "Duration of service",
      "Terms of payment",
      "Resources and charges",
      "Party authorization",
      "Signatures",
      "Annex 1",
      "Engagement of resource"
    ],
    requirements: [],
    createdBy: "",
    juniorLevelIndex: 0,
    midLevelIndex: 0,
    seniorLevelIndex: 0,
    userId: "",
    supplierId: "",
    commentList: [],
    openComments: false,
    commentStatusBlock: { block: false, title: "", message: "" },
    created_by: { email: "", first_name: "", last_name: "" },
    organisationRate: [],
    selectedRates: [],
    itemsToSend: [],
    availableSkills: [],
    selectedSkills: [],
    archiveContract: false,
    contract_type: "",
    basicRequiredFields: [],
    serviceAgreementDetails: [],
    trainingDetails: [],
    resourceFieldsDetails: [],
    emptyFields: [],
    fieldsEmpty: false,
    isContractUrl: false,
    isContractDoc: true,
    contractDocName: "",
    contractUrl: "",
    contractDoc: [],
    contractDocUrl: "",
    contractDocDate: "",
    contractStatus: "",
    selectContactPerson: false,
    clientContactPerson: {
      email: "",
      name: "",
      phone: "",
      id: "",
      client_id: ""
    },
    ccMails: "",
    ccMailsArray: [],
    emailAttachment: "",
    createMail: false,
    approverEmail: "",
    contactPersonEmail: "",
    approverSigned: false,
    clientSigned: false,
    updateContract: false
  }),
  getters: {
    organisationName: (state) =>
      state.listOfOrganisations?.find(
        (name) => name.id === state.organisationId
      )?.client_name,
    clientName: (state) =>
      state.listOfClients?.find((name) => name.id === state.clientId)
        ?.client_name,
    organisationStreetAddress: (state) =>
      state.listOfOrganisations?.find(
        (name) => name.id === state.organisationId
      )?.address?.street_address,
    organisationCountry: (state) =>
      state.listOfOrganisations?.find(
        (name) => name.id === state.organisationId
      )?.address?.country,
    organisationState: (state) =>
      state.listOfOrganisations?.find(
        (name) => name.id === state.organisationId
      )?.address?.state_region,
    organisationCity: (state) =>
      state.listOfOrganisations?.find(
        (name) => name.id === state.organisationId
      )?.address?.city,
    organisationContactPerson: (state) =>
      state.listOfOrganisations
        ?.find((name) => name.id === state.organisationId)
        ?.contact_person?.find(
          (person) => person !== null && person !== undefined
        )?.name,

    getRates: (state) => {
      return state.selectedRates?.map((item) => {
        return {
          description: `${item?.name} ${item?.specialisation?.name}`,
          rate:
            state.rateType === "Month"
              ? item?.specialisation?.rate_per_month
              : item?.specialisation?.rate_per_hour,
          numberOfResources: 0
        };
      });
    }
  },
  actions: {
    createRequirements() {
      this.requirements = this.selectedSpecialisations.map(() => ({
        organisationId: "",
        seniorityLevel: "",
        rates: []
      }));
    },
    resetState: function () {
      this.clientId = "";
      this.contractId = "";
      this.organisationId = "";
      this.effectiveDate = "";
      this.endDate = "";
      this.discount = "";
      this.includeTraining = "no";
      this.trainingDiscount = "";
      this.trainingDuration = "";
      this.trainingCount = "";
      this.trainingsSkillsNeeded = [];
      this.professionalServiceAgreement = "no";
      this.ratePerProject = "";
      this.suppliers = [];
      this.contractName = "";
      this.rateType = "";
      this.file_effective_date = "";
      this.file_url = "";
      this.agreementFiles = [];
      this.selectedSpecialisations = [];
      this.availableSkills = [];
      this.selectedSkills = [];
      this.fieldsEmpty = false;
      this.isContractUrl = false;
      this.isContractDoc = false;
      this.contractUrl = "";
      this.contractDoc = [];
      this.contractDocUrl = "";
      this.contractDocDate = "";
      this.emailAttachment = "";
      this.approver = {
        full_name: "",
        employee_id: "",
        work_email: ""
      };
      this.clientContactPerson = {
        email: "",
        name: "",
        phone: "",
        id: "",
        client_id: ""
      };
      this.approverSigned = false;
      this.clientSigned = false;
    },

    addRate(
      status: boolean,
      resource: specialization,
      seniorityLevel: rateType,
      filter: string
    ) {
      const name = seniorityLevel?.name;
      const levelId = seniorityLevel?.id;
      const specializationId = resource?.id;
      const itemExists = this.selectedRates.filter(
        (item) =>
          item.id === levelId && item.specialisation.id === specializationId
      );
      if (status === true) {
        if (!itemExists?.length) {
          this.selectedRates.push({
            id: levelId,
            name: name + " " + resource?.name,
            specialisation: resource,
            numberOfResources: 0,
            description: name + " " + resource?.name,
            rate:
              this.rateType === "Month"
                ? resource.rate_per_month
                : resource.rate_per_hour
          });
        } else {
          this.selectedRates = this.selectedRates?.filter(
            (item) =>
              item.id !== levelId && item.specialisation.id !== specializationId
          );
        }
      } else {
        const rate = this.selectedRates.find(
          (rateItem) =>
            rateItem.id === levelId &&
            rateItem.specialisation.id === resource.id
        );

        if (rate) {
          rate.specialisation.checked = !rate?.specialisation.checked;
        }
        const rateItem = this.organisationRate?.find(
          (item) =>
            item.id === levelId &&
            item.rate_specialization.find(
              (special) => special.id === specializationId
            )
        );
        if (rateItem) {
          const rateValue = rateItem.rate_specialization.find(
            (item) => item.id === specializationId
          );
          if (rateValue) {
            rateValue.checked = false;
          }
        }
        this.selectedRates = this.selectedRates?.filter(
          (item) =>
            item.id !== levelId || item.specialisation.id !== specializationId
        );
      }
    }
  }
});
