<template>
  <div
    class="N700 flex justify-end content-center pr-4"
    @mouseleave="removeDropdowns"
  >
    <!-- <p class="sm:P300 P200 self-center">Welcome back {{ firstName }} 👋🏻</p> -->
    <div class="flex gap-4">
      <div
        class="
          self-center
          cursor-pointer
          flex
          gap-2
          bg-[#D8DAE5]
          p-2
          rounded-lg
        "
        @click="showAppsDropdown = !showAppsDropdown"
      >
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" />
        <p class="text-[#101840]">Apps</p>
      </div>
      <div class="self-center bg-[#D8DAE5] p-2 rounded-lg">
        <img
          src="../assets/menu-icons/notification-bing-grey.svg"
          class="notification"
        />
      </div>
      <div
        class="flex gap-4"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <img
          :src="profilePicture"
          class="w-10 h-10 rounded-full self-center profile-img"
        />
        <p class="self-center N800 username">{{ userName }}</p>
        <img
          src="@/assets/arrow-down.svg"
          class="cursor-pointer h-fit self-center"
        />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "@/store/index";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppsNavigation.vue";

const store = useStore();
const firstName = ref(store.userInfo.first_name);
const userName = ref(store.user.name);

const profilePicture = ref(store.profilePicture);
const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};
</script>
<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }
  .username {
    display: none;
  }
}
</style>
