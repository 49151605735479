<template>
  <div class="absolute bg-white w-fit p-6 rounded right-10 top-14 shadow-md">
    <h3 class="H300 N900 mb-4">Apps</h3>
    <div class="grid grid-cols-4 gap-6 cursor-pointer">
      <TooltipComponent
        v-for="(app, index) in apps"
        :key="index"
        :text="app.text"
      >
        <a :href="app.link">
          <component :is="app.icon" />
          <span class="N700 P100">{{ app.text.slice(0, 6) }}...</span>
        </a>
      </TooltipComponent>
    </div>
  </div>
</template>

<script setup lang="ts">
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";
import config from "@/config/config";
import PerformanceSvg from "../assets/menu-icons/PerformanceSvg.vue";
import ResourceSvg from "../assets/menu-icons/ResourceSvg.vue";
import EmployeeManagerSvg from "../assets/menu-icons/EmployeeManagerSvg.vue";
import ContractSvg from "../assets/menu-icons/ContractSvg.vue";
import ClockITSvg from "../assets/menu-icons/ClockITSvg.vue";
import PayrollSvg from "../assets/menu-icons/PayrollSvg.vue";
import InvoiceSvg from "../assets/menu-icons/InvoiceSvg.vue";
import ProcurementSvg from "../assets/menu-icons/ProcurementSvg.vue";
import LeaveSvg from "../assets/menu-icons/LeaveSvg.vue";
import IncidenceManagerSvg from "../assets/menu-icons/IncidenceManagerSvg.vue";
import MeetingRoomBookingSvg from "../assets/menu-icons/MeetingRoomBookingSvg.vue";

const apps = [
  {
    text: "Performance manager",
    link: config.performanceLink,
    icon: PerformanceSvg
  },
  { text: "Resource manager", link: config.resourceLink, icon: ResourceSvg },
  {
    text: "Employee manager",
    link: config.employeeManagerLink,
    icon: EmployeeManagerSvg
  },
  // { text: "Contract manager", link: config.contractManagerLink, icon: ContractSvg },
  { text: "Clock-IT", link: config.clockItLink, icon: ClockITSvg },
  { text: "Payroll", link: config.payrollLink, icon: PayrollSvg },
  {
    text: "Invoice manager",
    link: config.invoiceManagerLink,
    icon: InvoiceSvg
  },
  {
    text: "Procurement manager",
    link: config.procurementManagerLink,
    icon: ProcurementSvg
  },
  { text: "Leave manager", link: config.leaveManagerLink, icon: LeaveSvg },
  { text: "Loan manager", link: config.loansManagerLink, icon: PayrollSvg },
  {
    text: "Incidence manager",
    link: config.incidenceManagerLink,
    icon: IncidenceManagerSvg
  },
  {
    text: "Meeting room booking",
    link: config.meetingRoomBookingLink,
    icon: MeetingRoomBookingSvg
  }
];
</script>
