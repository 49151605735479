<template>
  <div
    class="
      absolute
      sm:right-14
      right-0
      top-14
      bg-white
      w-fit
      pl-3
      pr-12
      py-2.5
      shadow-md
      z-10
      rounded
      N800
      P200
    "
  >
    <a class="flex gap-2 mb-4 cursor-pointer">
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a
      class="flex gap-2"
      href="javascript:void(0);"
      @click.prevent="userLogout()"
    >
      <img src="@/assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/apps/authentication/store/store_access";
import { logout } from "@/services/auth";
import { useStore } from "@/store";

const store = useStore();
const env = process.env;
const Access = useAccessStore();
const userLogout = () => {
  Access.$reset();
  logout().then(() => {
    window.location.href = `https://amalitech-sso.amalitech-dev.net/logout?login-hint=${store?.loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}/create-password`;
  });
};
</script>
