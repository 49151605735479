<template>
  <div
    class="settings px-23"
    style="border: 1px solid #e6e8f0"
    :class="
      homeConditions && $router.currentRoute.value.name != 'home'
        ? 'pt-24'
        : 'pt-4'
    "
  >
    <div
      @click="store.showAll = !store.showAll"
      class="flex mx-auto justify-center w-4/5 gap-5 px-5 cursor-pointer"
    >
      <div :class="store.showAll ? 'lg:ml-10' : ''">
        <BurgerSVG />
      </div>
      <p
        class="
          mt-2
          text-[27px]
          N700
          font-bold
          sm:hidden
          md:hidden
          lg:block
          xl:block
          mr-24
        "
        v-if="store.showAll"
      >
        AmaliTech
      </p>
    </div>
    <div class="pt-5 px-3 mb-4"><hr /></div>

    <div
      v-if="!viewResource && !env.VUE_APP_RESOURCE_MANAGER"
      class="flex flex-col h-[80vh]"
    >
      <div
        :class="store?.showAll ? 'px-4' : 'px-4'"
        class="flex flex-col justify-center"
      >
        <!-- Dashboard -->
        <NavigationComponent
          :item="{ label: 'Dashboard', route: '/home' }"
          @mouseover="toggleOnPageIcon('home', true)"
          @mouseout="toggleOnPageIcon('home', false)"
        >
          <HomeSvg
            :color="
              onHome || $route?.path === '/home' || $route?.path === '/'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>

        <!-- Contract Manager -->
        <NavigationComponent
          :item="{
            label: 'Contract Manager',
            route:
              $route.path === '/create-contract'
                ? '/create-contract'
                : '/contract-manager'
          }"
          @mouseover="toggleOnPageIcon('manager', true)"
          @mouseout="toggleOnPageIcon('manager', false)"
        >
          <ContractSvg
            :color="
              onManager ||
              $route?.path === '/contract-manager' ||
              $route?.path === '/create-contract' ||
              $route?.path === '/edit-contract'
                ? '#DD5928'
                : '#8F95B2'
            "
            :width="'24'"
            :height="'24'"
          />
        </NavigationComponent>

        <!-- My Approvals -->
        <NavigationComponent
          :item="{ label: 'My Approvals', route: '/contract-approvals' }"
          @mouseover="toggleOnPageIcon('approvals', true)"
          @mouseout="toggleOnPageIcon('approvals', false)"
        >
          <ApprovalSVG
            :color="
              onApprovals || $route?.path === '/contract-approvals'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>

        <!-- Settings -->
        <div class="px-3"><hr /></div>
        <NavigationComponent
          :item="{ label: 'Settings', route: '/configurations' }"
          @mouseover="toggleOnPageIcon('settings', true)"
          @mouseout="toggleOnPageIcon('settings', false)"
        >
          <SettingsSVG
            :color="
              onSettings || $route?.path === '/configurations'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/apps/authentication/store/store_access";
import { viewTab } from "../helpers/newGroupPermissions";
import SettingsSVG from "@/assets/SettingsSVG.vue";
import NavigationComponent from "./NavigationComponent.vue";
import { ref } from "vue";
import { useStore } from "../store";
import ApprovalSVG from "../apps/contract-management/assets/ApprovalSVG.vue";
import { encodeUrlPath } from "../helpers/utils";
import ContractSvg from "@/assets/menu-icons/ContractSvg.vue";
import BurgerSVG from "@/apps/contract-management/assets/BurgerSVG.vue";
import HomeSvg from "@/apps/contract-management/assets/HomeSvg.vue";

// user permissions

defineProps({
  homeConditions: { type: Boolean, required: true }
});
const emits = defineEmits(["appSelected"]);
const Access = useAccessStore();
const appsPerm = Access.permissions.resource_manager;
const store = useStore();
const user_id = ref(store.user.id);
const storeActive = store.showAll;

let encryptedId = encodeUrlPath(user_id.value?.toString());

const env = process.env;
const viewResource = env.VUE_APP_STAGING
  ? appsPerm.view_talent_pool ||
    appsPerm.view_skills_manager ||
    appsPerm.view_client_manager ||
    appsPerm.view_project_manager
  : viewTab(appsPerm);
const onDocuments = ref(false);

const onHome = ref(false);
const onManager = ref(false);
const onApprovals = ref(false);
const onSettings = ref(false);

const toggleOnDocuments = (status: boolean) => {
  onDocuments.value = status;
};

const toggleOnPageIcon = (page: string, status: boolean) => {
  if (page === "home") {
    onHome.value = status;
  }
  if (page === "manager") {
    onManager.value = status;
  }
  if (page === "approvals") {
    onApprovals.value = status;
  }
  if (page === "settings") {
    onSettings.value = status;
  }
};
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  /* padding: 15px 23px 0 23px; */
  color: #474d66;
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.settings::-webkit-scrollbar {
  display: none;
}
ul {
  margin: 19px 0;
}
li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.H700 {
  z-index: 2;
}
@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}
@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }
  .menu_view {
    width: auto;
  }
}
@media (max-width: 670px) {
}
</style>
