<template>
  <div>
    <h4>Dashboard</h4>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { setToken, decodeToken } from "@/services/auth";
import { useStore } from "@/store";
import { onBeforeMount } from "vue";

const store = useStore();
const urlQuery = router.currentRoute.value.query;
const appToken = urlQuery["app-token"];

const loginHint = urlQuery["login-hint"];
onBeforeMount(() => {
  if (appToken) {
    setToken(appToken as string);
    store.setUser(decodeToken());
    store.setLoginHint(loginHint as string);
    router.replace({ name: "home" });
  }
});
</script>
