import PageNotFound from "../../PageNotFound.vue";

export default [
  {
    path: "/contract-manager",
    name: "ContractManager",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "contract" */ "../views/ContractManager.vue")
  },
  {
    path: "/create-contract",
    name: "CreateContractStepper",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "contract" */ "../views/CreateContractStepper.vue"
      )
  },
  {
    path: "/view-contract/:id",
    name: "ViewContract",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "contract" */ "../views/ViewContract.vue")
  },

  {
    path: "/contract-manager-client/:id",
    name: "ContractManagerClient",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "contract" */ "../views/ContractManagerClient.vue"
      )
  },
  {
    path: "/contract-approvals",
    name: "ViewContractApprovals",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "contract" */ "../views/ContractApproval.vue")
  },

  {
    path: "/edit-contract/:id",
    name: "EditContract",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "contract" */ "../views/CreateContractStepper.vue"
      )
  },

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: { auth: true },
    component: PageNotFound
  }
];
